<template>
<div class="customerManagement" v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中"
  element-loading-background="rgba(255, 255, 255, 0.5)">
    <el-select v-model="pageInfo.companyId" filterable clearable placeholder="选择公司" @change="find">
        <el-option
        v-for="item in companyCodeList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
        >
        </el-option>
    </el-select>
    <el-input v-model="pageInfo.keyword" style="width:200px;marginLeft:20px" placeholder="输入公司名称/负责人" @keyup.enter.native = "find"></el-input>
    <el-button type="primary" icon="el-icon-search" style="marginLeft:20px" @click="find">查询</el-button>
    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="add">新增</el-button>
    <tableDialog :tableList="tableData" @setTableHead="setTableHead" style="margin-right:20px;float:right"></tableDialog>
    <el-table
    ref="multipleTable"
    :data="tableList"
    tooltip-effect="dark"
    style="width: 100%; marginTop:20px"
    border
    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
    >
        <template v-for="item in tableHead" >
            <el-table-column 
            v-if="item.prop!='lastRecord'"
            :key="item.prop"
            :prop="item.prop"
            :label="item.name"
            header-align="center"
            align="center"
            :width="item.width"
            >
            </el-table-column>
            <el-table-column 
            v-if="item.prop=='lastRecord'"
            :key="item.prop"
            :prop="item.prop"
            :label="item.name"
            header-align="center"
            align="center"
            width="220"
            >
                <template slot-scope="scope">
                    <div class="follow">
                        <p class="situation">{{scope.row.lastRecord}}</p>
                        <el-link type="primary" @click="viewFollow(scope.row.id)">查看</el-link>
                    </div>
                </template>
            </el-table-column>
        </template>
        <el-table-column header-align="center" align="center" label="操作" width="200">
            <template slot-scope="scope">
                <div class="operation">
                    <el-link type="primary" @click="changeClient(scope.row.id)" icon="el-icon-edit">修改</el-link>
                    <el-link type="primary" @click="viewDetail(scope.row.id)" icon="el-icon-zoom-in">查看</el-link>
                    <el-link type="primary" @click="del(scope.row.id)" icon="el-icon-delete">删除</el-link>
                </div>
            </template>
        </el-table-column>
    </el-table>
    <div class="block" style="textAlign:center;marginTop:20px;paddingRight:20px">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[10 ,20, 30, 40]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
    <el-dialog
    :title="title"
    :visible.sync="addDialog"
    width="30%"
    :before-close="handleClose"
    >
        <el-form v-loading="dialogLoading" class="newCustomer" ref="form" :model="addForm" label-width="80px">
            <el-form-item label="公司标识">
                <el-select v-model="addForm.companyId" filterable placeholder="下拉选择公司标识" :disabled="disabled">
                    <el-option 
                    :label="item.value" 
                    :value="item.id"
                    v-for="item in companyCodeList"
                    :key="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="公司名称">
                <el-input placeholder="请输入公司名称" v-model="addForm.companyName" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="公司地址">
                <el-input placeholder="请输入公司地址" v-model="addForm.address" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="负责人">
                <el-input placeholder="请输入公司负责人" v-model="addForm.leaderName" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="职位">
                <el-input placeholder="请输入负责人职位" v-model="addForm.position" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
                <el-input placeholder="请输入联系方式" v-model="addForm.contact" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input placeholder="请输入备注" v-model="addForm.tips" :disabled="disabled"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="next" :disabled="disabled">{{this.nweChange?'下一步':'保存'}}</el-button>
        </span>
    </el-dialog>
    <el-dialog
    title="新增跟进记录"
    :visible.sync="FollowUpRecordsOut"
    style="padding:0 40px 0 0"
    width="30%"
    >
        <el-form ref="follow" :model="follow" label-width="80px">
            <el-form-item label="活动名称">
                <el-input placeholder="请输入活动名称" v-model="follow.name"></el-input>
            </el-form-item>
            <el-form-item label="跟进地点">
                <el-input v-model="follow.address" placeholder="请输入跟进地点"></el-input>
            </el-form-item>
            <el-form-item label="日期时间">
                <div class="block">
                    <el-date-picker
                    v-model="date"
                    type="date"
                    placeholder="选择日期"
                    style="width:165px">
                    </el-date-picker>
                    -
                    <el-time-select
                    v-model="time"
                    :picker-options="{
                        start: '00:00',
                        step: '00:15',
                        end: '23:59'
                    }"
                    placeholder="选择时间"
                    style="width:165px">
                    </el-time-select>
                </div>
            </el-form-item>
            <el-form-item label="是否上门">
                <el-switch 
                v-model="follow.effectiveFlag"
                active-value="1"
                inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item label="跟进性质">
                <el-radio-group v-model="follow.followType">
                    <el-radio :label="item.id" v-for="item in getFollowType" :key="item.id">{{item.value}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="跟进内容">
                <el-input type="textarea" v-model="follow.content"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="addRecords">保存</el-button>
        </span>
    </el-dialog>
    <el-dialog
    title="跟进记录"
    :visible.sync="followRecords"
    width="70%"
    :before-close="closeFind"
    v-loading='dialogLoading'
    >
        <el-button type="primary" icon="el-icon-plus" style="marginBottom:20px" @click="addFollowList">新增</el-button>
        <el-table
        :data="followTable"
        style="width: 100%"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}"
        border>
            <el-table-column
            v-for="item in followList"
            :key="item.prop"
            :prop="item.prop"
            :label="item.name"
            header-align="center"
            align="center"
            >
            </el-table-column>
            <el-table-column
            prop="date"
            label="操作"
            header-align="center"
            align="center"
            width="170"
            >
                <template slot-scope="scope">
                    <div class="operat">
                        <el-link type="primary" @click="detailFollow(scope.row.id)">详情</el-link>
                        <el-link type="primary" @click="addFollowList('change',scope.row.id)">修改</el-link>
                        <el-link type="primary" @click="followDel(scope.row.id)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="block" style="textAlign:right;margin:20px 20px">
            <el-pagination
            :current-page="followPage.current"
            @size-change="followSizeChange"
            @current-change="followCurrentChange"
            :page-sizes="[15, 20, 30, 40]"
            :page-size="followPage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total='followTotal'
            >
            </el-pagination>
        </div>
        <el-dialog
        :title="followTitle"
        :visible.sync="FollowUpRecords"
        style="padding:0 40px 0 0"
        width="30%"
        append-to-body
        :before-close="handleClose"
        >
            <el-form v-loading="insideLoading" ref="form" :model="form" label-width="80px">
                <el-form-item label="活动名称" :disabled="appendRecord">
                    <el-input placeholder="请输入活动名称" v-model="follow.name"></el-input>
                </el-form-item>
                <el-form-item label="跟进地点">
                    <el-input v-model="follow.address" placeholder="请输入跟进地点"></el-input>
                </el-form-item>
                <el-form-item label="日期时间">
                    <div class="block">
                        <el-date-picker
                            v-model="date"
                            type="date"
                            placeholder="选择日期"
                            style="width:165px">
                        </el-date-picker>
                            -
                        <el-time-select
                            v-model="time"
                            :picker-options="{
                                start: '00:00',
                                step: '00:15',
                                end: '23:59'
                            }"
                            placeholder="选择时间"
                            style="width:165px">
                        </el-time-select>
                    </div>
                </el-form-item>
                <el-form-item label="是否上门">
                    <el-switch 
                    v-model="follow.effectiveFlag"
                    active-value="1"
                    inactive-value="0"></el-switch>
                </el-form-item>
                <el-form-item label="跟进性质">
                    <el-radio-group v-model="follow.followType">
                        <el-radio :label="item.id" v-for="item in getFollowType" :key="item.id">{{item.value}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="跟进内容">
                    <el-input type="textarea" v-model="follow.content"></el-input>
                </el-form-item>
                <el-form-item label="跟进人">
                    <el-select v-model="followUserId" filterable placeholder="请选择跟进人">
                        <el-option
                            v-for="item in userList"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="changeRecords" v-if="appendRecord">保存</el-button>
            </span>
        </el-dialog>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="closeFind">保存并关闭</el-button>
        </span>
    </el-dialog>
    </div>
</template>

<script>
import tableDialog from '@/components/tableDialog.vue'
//接口
import {getList,addInit,getFollowType,addClient,followAdd,del,clientcInit,editClient,followrecordInit,start,search,detailFollow,FollowDel,alterFollow} from '@/api/customerManagement.js'
export default {
    components:{
      tableDialog
    },
    data(){
        return {
            insidLoading:false,
            dialogLoading:false,
            loading:false,
            title:'',
            followTitle:'',
            //开关
            sign:false,
            appendRecord:true,
            disabled:false,
            nweChange:true,
            followChange:true,
            //跟进人
            followUserId:'',
            userList:[],
            form:{
                type:[],
            },
            follow:{
                followType:'',
                name:'',
                address:'',
                date:'',
                effectiveFlag:"0",
                content:'',
            },
            orderId:'',
            date:'',
            time:'',
            addForm:{
                companyId:'',
                companyName:'',
                address:'',
                leaderName:"",
                position:"",
                contact:'',
                tips:''
            },
            //公司标识下拉
            companyCodeList:[],
            //跟进方式
            getFollowType:[],
            tableList:[],
            //表头内容
            tableData:[
                {
                    name:'公司标识',
                    prop:'companyCode'
                },
                {
                    name:'公司名称',
                    prop:'companyName'
                },
                {
                    name:'联系方式',
                    prop:'contact'
                },
                {
                    name:'公司负责人',
                    prop:'leaderName'
                },
                {
                    name:'职位',
                    prop:'position'
                },
                {
                    name:'地址',
                    prop:'address'
                },
                {
                    name:'跟进情况',
                    prop:'lastRecord',
                },
                {
                    name:'跟进日期',
                    prop:'lastRecordDate',
                    width:'180'
                },
                {
                    name:'跟进人',
                    prop:'lastRecordUser'
                },
                {
                    name:'备注',
                    prop:'tips'
                }
            ],
            tableHead:[],
            //跟进列表表头
            followList:[
                {name:'名称',prop:'name'},
                {name:'性质',prop:'followType'},
                {name:'时间',prop:'date'},
                {name:'内容',prop:'content'},
                {name:'跟进人',prop:'followUserName'}
            ],
            followTable:[],
            pageInfo:{
                pageSize:10,
                current:1,
                keyword:'',
                companyId:''
            },
            followPage:{
                current:1,
                pageSize:15,
            },
            followTotal:0,
            total:0,
            //跟踪记录
            followRecords:false,
            //控制新增弹窗
            addDialog:false,
            //控制表格内容显示弹窗
            tableSelect:false,
            //查看弹窗
            view:false,
            //查看跟进记录
            FollowUpRecords:false,
            FollowUpRecordsOut:false,
            id:'',
        };
    },
    async created(){
        this.tableHead=this.tableData
        let {data}=await start()
        this.companyCodeList=data.data.company.data
        this.search()
        
    },
    methods:{
        reset(){
            this.follow={
                followType:'',
                name:'',
                address:'',
                date:'',
                effectiveFlag:"0",
                content:'',
            }
            this.date=''
            this.time=''
            this.followUserId=''
        },
        //新增跟进记录初始化
        async addFollowList(ch,id){
            this.reset()
            //修改初始化部分
            if (ch=='change'){
                this.sign=false
                this.id=id
                this.detailFollow(id)
                this.appendRecord=true
                this.followTitle="修改跟进记录"
            }else{
                //新增部分
                this.followTitle="新增跟进记录"
                this.sign=true
                this.FollowUpRecords=true
                let {data}=await getFollowType()
                this.getFollowType=data.data.typeList
                this.userList=data.data.userList
            }
        },
        //新增
        async changeRecords(){
            if(this.sign){
                this.follow.date=`${this.$moment(this.date).format('YYY-MM-DD').substring(2)} ${this.time}:00`
                let {data}=await followAdd({followUserid:this.followUserId,orderId:this.orderId,...this.follow})
                if(data.code==200){
                        this.$message({
                        message:data.message,
                        type: 'success'
                        })
                    }else{
                        this.$message({
                            message: '未选择日期时间或服务器错误',
                            type: 'warning'
                        });
                        return
                    }
                this.FollowUpRecords=false
                this.PageList()
            }
            //查看跟踪记录修改部分
            if(!this.sign){
                this.follow.date=`${this.$moment(this.date).format('YYY-MM-DD').substring(2)} ${this.time}:00`
                let {data}=await alterFollow({id:this.id,followUserid:this.followUserId,orderId:this.orderId,...this.follow})
                    if(data.code==200){
                        this.$message({
                        message:data.message,
                        type: 'success'
                        })
                    }else{
                        this.$message({
                            message: data.message,
                            type: 'warning'
                        });
                    }
                this.FollowUpRecords=false
                this.PageList()
            }
        },
        //查看跟进====详情
        async detailFollow(id){
            this.insideLoading=true
            this.followTitle='跟进记录信息'
            this.FollowUpRecords=true
            this.appendRecord=false
            let {data}=await detailFollow(id)
            this.followUserId=data.data.followUserid
            this.follow.content=data.data.content
            this.follow.address=data.data.address
            this.follow.effectiveFlag=String(data.data.effectiveFlag)
            this.follow.name=data.data.name
            this.date=data.data.date.split(' ')[0]
            this.time=data.data.date.split(' ')[1].substring(0,5)
            this.getFollowType.forEach(item=>{
                if(item.value==data.data.followType){
                    this.follow.followType=item.id
                }
            })
            this.insideLoading=false
        },
        //跟进删除
        followDel(id){
             this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                FollowDel(id).then(({data:res})=>{
                    if(res.code==200){
                        this.$message({
                            type: 'success',
                            message: res.message
                        });
                        this.PageList()
                        if(this.followList<=1){
                            this.followPage.current-=1
                            this.PageList()
                        }
                    }else {
                        this.$message({
                            type: 'warning',
                            message: res.message
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            
        },
        //条件查询
        async search(){
            this.loading=true
            let data=await search(this.pageInfo)
            this.tableList=data.data.values
            this.pageInfo.pagesize=data.data.pageInfo.pageSize
            this.total=data.data.pageInfo.total
            this.loading=false
        },
        //查看客户
        viewDetail(id){
            this.changeClient(id)
            this.disabled=true
            this.title="客户信息"
        },
        handleClose(){
            this.addDialog=false
            this.FollowUpRecordsOut=false
            this.disabled=false
            this.FollowUpRecords=false
            this.appendRecord=true
            this.search()
            this.reset()
        },
        followCurrentChange(val){
            this.followPage.current=val
            this.PageList()
        },
        followSizeChange(val){
            this.followPage.pageSize=val
            this.PageList()
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.search()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.search()
        },
        handleSelectionChange(){

        },
        async getTable(){
            let {data}=await getList()
            this.tableList=data.data
        },
        //开始新增
        async add(){
            this.title="新增客户"
            this.addForm={
                companyId:'',
                companyName:'',
                address:'',
                leaderName:"",
                position:"",
                contact:'',
                tips:''
            }
            this.nweChange=true
            this.addDialog=true
            let{data}=await addInit()
            this.companyCodeList=data.data.company.data
        },
        async next(){
            //添加客户信息
            if(this.nweChange){
                this.addDialog=false
                this.FollowUpRecordsOut=true
                let {data}=await getFollowType()
                if(data.code==200){
                    this.$message({
                        message: data.message,
                        type: 'success'
                    });
                }
                this.getFollowType=data.data.typeList
                let res=await addClient(this.addForm)
                this.orderId=res.data.data.id
                this.search()
                this.reset()
            }
            //修改客户信息
            if(!this.nweChange){
                this.dialogLoading=true
                let {data}=await editClient({id:this.id,...this.addForm})
                if(data.code==200){
                    this.$message({
                        showClose: true,
                        message:data.message,
                        type: 'success'
                    });
                    this.dialogLoading=false
                    this.search()
                    this.addDialog=false
                }else{
                    this.$message.error('修改失败');
                    this.dialogLoading=false
                }
            }
            
        },
        async addRecords(){
            this.follow.date=`${this.$moment(this.date).format('YYY-MM-DD').substring(2)} ${this.time}:00`
            let {data}=await followAdd({followUserid:this.followUserId,orderId:this.orderId, ...this.follow})
            console.log(data.code);
            if(data.code!='200'){
                this.$message({
                    showClose: true,
                    message:'未选择时间或服务器错误',
                    type: 'warning'
                });
                return
            }
            this.$message({
                showClose: true,
                message:data.message,
                type: 'success'
            });
            this.search()
            this.FollowUpRecordsOut=false
            this.follow={
                followType:'',
                name:'',
                address:'',
                date:'',
                effectiveFlag:"0",
                content:'',
            }
            this.date=''
            this.time=''
        },
        //删除客户
        del(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    del(id).then(res=>{
                        if(res.data.code==200){
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.search()
                        }
                        if(this.tableList.length<=1){
                            this.pageInfo.current-=1
                            this.search()
                        }
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        async changeClient(id){
            this.dialogLoading=true
            this.title="修改客户信息"
            this.nweChange=false
            this.addDialog=true
            let {data}=await clientcInit(id)//修改初始化
            this.companyCodeList=data.data.company.data
            let detail=data.data.detail
            this.addForm.address=detail.address
            this.addForm.companyId=detail.companyId
            this.addForm.companyName=detail.companyName
            this.addForm.leaderName=detail.leaderName
            this.addForm.position=detail.position
            this.addForm.contact=detail.contact
            this.addForm.tips=detail.tips
            this.id=detail.id
            this.dialogLoading=false
        },
        //查看跟进记录
        async viewFollow(id){
            this.loading=true
            this.id=id
            this.orderId=id
            // this.followRecords=true
            let res=await followrecordInit({orderId:this.orderId,...this.followPage})
            this.followTable=res.data.values
            this.followTotal=res.data.pageInfo.total
            // this.addFollowList()
            let {data}=await getFollowType()
            this.getFollowType=data.data.typeList
            this.userList=data.data.userList
            this.loading=false
            this.followRecords=true
        },
        async PageList(){
            let res=await followrecordInit({orderId:this.orderId,...this.followPage})
            this.followTable=res.data.values
            this.followTotal=res.data.pageInfo.total

        },
        setTableHead(list){
          this.tableHead=list
        },
        closeFind(){
            this.followRecords=false
            this.search()
        },
        find(){
            this.pageInfo.current=1
            this.search()
        }

    }
};
</script>

<style scoped lang="scss">
.search {
  display: flex;
  justify-content: flex-end;
}
.operation{
    display: flex;
    justify-content: space-evenly;
}
.newCustomer{
    ::v-deep .el-input__inner{
        width: 420px;
    }
}
.follow{
    display: flex;
    justify-content: space-between;
}
.operat{
    display: flex;
    justify-content: space-around;
}
.situation{
    width: 170px;
    text-align: left;
}
</style>