import request from '@/utils/request'

//查询初始化
export function getList(params){
    return request({
        url:'/api/follow/list',
        method:'get',
        params
    })
}

//新增模块新增初始化
export function addInit(){
    return request({
        url:'/api/follow/addInit',
        method:'get'
    })
}

//新增模块新增
export function addClient(data){
    return request({
        url:'/api/follow',
        method:'post',
        data
    });
}

//客户拜访跟进新增初始化
export function getFollowType(){
    return request({
        url:'/api/followrecord/addInit',
        method:'get'
    });
}

//跟进新增
export function followAdd(data){
    return request({
        url:'/api/followrecord',
        method:'post',
        data
    })
}
//跟进详情
export function detailFollow(id) {
    return request({
        url:`/api/followrecord/${id}`,
        method:'get'
    })
}
//跟进修改
export function alterFollow(data){
    return request({
        url:'/api/followrecord',
        method:'put',
        data
    });
}
//跟进删除
export function FollowDel(id){
    return request({
        url:`/api/followrecord/${id}`,
        method:'delete'
    });
}
//客户拜访-跟进记录模块-编辑初始化
export function followrecordInit(params){
    return request({
        url:`/api/followrecord/listPage`,
        method:'get',
        params
    })
}

//删除
export function del(id){
    return request({
        url:`/api/follow/${id}`,
        method:'delete'
    })
}
//客户管理编辑初始化
export function clientcInit(id){
    return request({
        url:`/api/follow/updateInit/${id}`,
        method:'get'
    });
}
//客户管理编辑
export function editClient(data){
    return request({
        url:`/api/follow`,
        method:'put',
        data
    });
}



//顶部查询初始化
export function start(){
    return request({
        url:'/api/follow/pageInit',
        method:'get'
    });
}
//查询
export function search(params){
    return request({
        url:'/api/follow/listPage',
        method:'get',
        params
    })
}
